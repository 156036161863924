<div class="flex align-items-center gap-3 mb-3">
  <div><i class="pi pi-user secondary-text-color" style="font-size: 2rem"></i></div>
  <div>{{usersName}} &mdash; Account#: {{usersAccountNumber}}</div>
</div>
<div class="flex gap-3">
  <button pButton pRipple label="Orders" class="primary-button" icon="pi pi-history" (click)="onNavigate('/' + routes.CUSTOMER + '/' + routes.ORDERS)"></button>
  <button pButton pRipple label="Open Invoicies" class="primary-button" icon="pi pi-book" (click)="onNavigate('/' + routes.CUSTOMER + '/' + routes.OPEN_INVOICES)"></button>
  <button pButton pRipple label="Settings" class="primary-button" icon="pi pi-wrench" (click)="onNavigate('/' + routes.CUSTOMER + '/' + routes.SETTINGS)"></button>
  <button pButton pRipple label="Sign Out" class="primary-button" icon="pi pi-sign-out" (click)="onLogoutClick()"></button>
</div>
